import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import ArrowRightIcon from '../../../static/assets/icons/navigation/arrow-right.svg'

import style from './StyledLink.module.scss'

const StyledLink = ({
  icon, disabled, className, children, ...props
}) => {
  const iconSVG = icon ? (<SVG src={icon} />) : (<SVG src={ArrowRightIcon} />)

  return (
    <div
      className={`${style.block} ${disabled ? style.disabled : ''} ${className || ''}`}
      {...props}
    >
      <div className={style.label}>
        {children}
      </div>
      <div className={style.icon}>
        {iconSVG}
      </div>
      <div className={style.active}>
        {iconSVG}
      </div>
    </div>
  )
}

StyledLink.propTypes = {
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
}

export default StyledLink
